<template>
  <div class="profile-menu">
    <div class="arrow">
      <div class="empty"/>
    </div>

    <div class="menu">
      <nuxt-link :to="{ name: 'user-dashboard' }"
                 class="menu-entry"
                 @click.native="close">
        <i class="fas fa-user-circle"/>
        My Account
      </nuxt-link>
      <nuxt-link :to="{ name: 'user-my_orders', query: { active_key: 'my_orders' } }"
                 class="menu-entry"
                 @click.native="close">
        <i class="fas fa-history"/>
        My Orders
      </nuxt-link>
      <nuxt-link to="/pages/return-policy"
                 class="menu-entry"
                 @click.native="close">
        <i class="fas fa-exchange-alt"/>
        Make a Return
      </nuxt-link>
      <div class="menu-entry" @click="signOut">
        <i class="fas fa-sign-out-alt"/>
        Sign out
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'profile-menu',
    computed: {
      productionEnv () {
        return process.env.PRODUCTION
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      async signOut () {
        this.close()

        try {
          await this.$store.dispatch('auth/signOut')
          this.$router.push({ name: 'index' })
        }
        catch (error) {
          console.error(error)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .profile-menu {
    background: #fff;
    position: absolute;
    transform: translateX(calc(-100% + 23px));
    border: 1px solid #cccccc;
    z-index: 99998;
    min-width: 155px;
    max-width: 300px;
    margin-top: 24px;

    @media only screen and (max-width: 768px) {
      transform: translateX(calc(-50% - 11px));
    }

    .menu {
      margin-top: 10px;
      width: 200px;
      color: #2e2e2e;

      .menu-entry {
        cursor: pointer;
        position: relative;
        padding: 10px 20px;
        display: block;

        font-family: OpenSans;
        font-size: 1.6rem;
        color: #2e2e2e;
        line-height: 1.38;
        letter-spacing: 0.38px;

        i {
          width: 18px;
          text-align: center;
        }

        &:hover {
          background: #eee9e7;
          font-weight: bold;
        }
      }
    }

    .arrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 11px 25px 11px;
      border-color: transparent transparent #cccccc transparent;
      line-height: 0px;
      _border-color: #000000 #000000 #cccccc #000000;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#cccccc');
      position: absolute;
      top: -25px;
      right: -1px;

      @media only screen and (max-width: 768px) {
        right: 66px;
      }

      .empty {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 25px 10px;
        border-color: transparent transparent #fff transparent;
        line-height: 0px;
        _border-color: #000000 #000000 #fff #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#fff');

        position: absolute;
        right: -10px;
      }
    }
  }
</style>
